import React from 'react';
import './index.css';

import Card from "../../components/Card/index.js";


const CompanyPage = () => {
    return (
        <>
            <div className="CompanyPage desktop">
                <img src="/assets/company/c1.png" className='banner1'/>
                <div className='banner2'>
                    <img src="/assets/company/c2.png" className='banner2-left'/>
                    <div className='description'>
                        <div className='text'>
                            索诺瓦 SONOVA 集团创立于 1947 年，
                            业务遍布全球 100 多个国家，
                            有超过 14,000 名高度敬业的员工。
                        </div>
                        <div className='text'>
                            在索诺瓦，我们的愿景明确。我们培育一个世界，在这里让每一个听力损失人士都能获得解决方案，并与所有人一起享受聆听带来的快乐。
                        </div>
                    </div>
                </div>
                <div className='banner3'>
                    <div className='description'>

                        <div className='title'>
                            <img src="/assets/company/c5.png" alt=""/>
                        </div>
                    </div>
                    {/*播放视频*/}
                    <div className='banner3-left'>
                        <video controls>
                            <source src="https://sonova-home-v2.oss-cn-hangzhou.aliyuncs.com/evp%20video.mp4" type="video/mp4"/>
                        </video>
                    </div>
                </div>
                <img src="/assets/company/c4.png" className='banner1'/>
                <div className="card-container">
                    <Card width={650} height={200} title='查询您附近的门店' other='立即探索' bgImg='assets/about/c6.png'
                          color="#FFF" titleSize={36} url='/store-query' />
                    <Card width={650} height={200} title='我们的服务' other='立即探索' bgImg='assets/about/c7.png' color="#FFF"
                          titleSize={36} url='/service' />
                </div>
            </div>

            <div className="CompanyPage mobile">

                <img src="/assets/company/mobile-c1.png" className='banner'/>

                <video  className='banner' controls>
                    <source src="https://sonova-home-v2.oss-cn-hangzhou.aliyuncs.com/evp%20video.mp4" type="video/mp4"/>
                </video>
                <img src="/assets/company/mobile-c2.png" className='banner'/>

                <div className="card-container">
                    <img src="/assets/about/mobile-c2.png" alt="" onClick={() => {
                        navigator('/store-query')
                    }}/>
                    <img src="/assets/about/mobile-c3.png" alt="" onClick={() => {
                        navigator('/service')
                    }}/>
                </div>
            </div>
        </>
    )
}
export default CompanyPage;