import React from 'react';
import './index.css';
import Button from "../../components/Button";
import Card from "../../components/Card";
import Icon from "../../components/Icon";
import {useNavigate, useSearchParams} from "react-router-dom";
import CardHeader from "../../components/CardHeader/index.js";
import {storeList} from "../Store/index.js";


const getCity = (province) => {
    var gc1 = [];
    gc1['上海市'] = ['上海市'];
    gc1['江苏省'] = ['苏州市'];
    gc1['江苏省'] = ['南京市'];
    gc1['浙江省'] = ['杭州市'];
    return gc1[province]
}
const getArea = (province, city) => {
    var gc2 = [];
    gc2['上海市'] = []
    gc2['上海市']['上海市'] = ['徐汇区', '长宁区', '静安区', '普陀区', '虹口区', '杨浦区', '闵行区', '松江区'];
    gc2['江苏省'] = []
    gc2['江苏省']['苏州市'] = ['姑苏区'];
    gc2['江苏省']['南京市'] = ['鼓楼区'];
    gc2['浙江省'] = [];
    gc2['浙江省']['杭州市'] = ['上城区'];
    return gc2[province][city]
}
const StoreQueryPage = () => {
    //获取参数
    const [getParams, setParam] = useSearchParams()
    //省
    const [provinces, setProvinces] = React.useState(['请选择','上海市', '江苏省','浙江省'])
    //市
    const [cities, setCities] = React.useState(['请选择'])
    //区
    const [areas, setAreas] = React.useState(['请选择'])
    //选中的省
    const [province, setProvince] = React.useState('')
    //选中的市
    const [city, setCity] = React.useState('')
    //选中的区
    const [area, setArea] = React.useState('')
    //查询到的门店
    const [stores, setStores] = React.useState([])

    const id = getParams.get('id')
    const navigator = useNavigate()
    const handleChange = (name, e) => {

        e.preventDefault()

        switch (name) {


            case "province": {
                setProvince(e.target.value);
                setCities(getCity(e.target.value))
                setCity(getCity(e.target.value)[0])
                setAreas(getArea(e.target.value, getCity(e.target.value)[0]))
                setArea(getArea(e.target.value, getCity(e.target.value)[0])[0])
                break;

            }

            case "city": {
                setCity(e.target.value);
                setAreas(getArea(province, e.target.value))
                setArea(getArea(province, e.target.value)[0])

                break;
            }

            case "area": {
                setArea(e.target.value)

                break;
            }

            default:
                alert("child handleChange error")

        }


    }

    return (
        <>
            <div className="StoreQueryPage mobile">
                <CardHeader title="门店查询"/>
                <div className='searchContainer'>
                    {/*选择框*/}
                    <div className='selectContainer'>
                        <select placeholder='选择省份' name='province' onChange={(e) => {
                            handleChange('province', e)
                        }}>
                            {provinces.map((province, index) => (
                                <option value={province} key={index}>{province}</option>
                            ))}
                        </select>
                        <select placeholder='选择城市' name='city' onChange={(e) => {
                            handleChange('city', e)
                        }}>
                            {cities.map((city, index) => (
                                <option value={city} key={index}>{city}</option>
                            ))}
                        </select>
                        <select placeholder='选择区域' name='area' onChange={(e) => {
                            handleChange('area', e)
                        }}>
                            {areas.map((area, index) => (
                                <option value={area} key={index}>{area}</option>
                            ))}
                        </select>
                    </div>

                    <Button type='primary-green' label="立即查询" handleClick={() => {
//找到storeList中省市区相等的门店
                        const storeListFilter = storeList.filter((store) => {
                            return store.province === province && store.city === city && store.area === area
                        })
                        setStores(storeListFilter);

                    }}/>
                </div>
                {
                    stores.map((store, index) => {
                        return <div className="map-container" onClick={()=>{
                            //跳转到门店详情页
                            navigator(`/store-detail?position=${store.position}`);

                        }}>
                            <img src={store.storeImg} alt=""/>
                            <div className='storeContainer'>
                                <div className='storeName'>
                                    傲诺瓦助听器{store.type} {store.position}
                                </div>
                                <div className="info">
                                    <div className='item'>
                                        <Icon type='position'/>
                                        {store.address}
                                    </div>
                                    <div className='item'>
                                        <Icon type='time'/>
                                        {store.time}
                                    </div>
                                    <div className='item'>
                                        <Icon type='men'/>
                                        {store.phone}
                                    </div>
                                </div>
                            </div>
                        </div>
                    })
                }


                <div className="card-container">
                    <img src="/assets/store/query/c1_mobile.png" alt="" onClick={()=>{
                        navigator('/store')
                    }}/>
                    <img src="/assets/store/query/c2_mobile.png" alt="" onClick={()=>{
                        navigator('/appointment')
                    }}/>

                </div>

            </div>
            <div className="StoreQueryPage desktop">
                <CardHeader title="门店查询"/>
                <div className='searchContainer'>
                    {/*选择框*/}
                    <select placeholder='选择省份' name='province'  onChange={(e) => {
                        handleChange('province', e)
                    }}>
                        {provinces.map((province, index) => (
                            <option value={province} key={index}>{province}</option>
                        ))}
                    </select>
                    <select placeholder='选择城市' name='city'  onChange={(e) => {
                        handleChange('city', e)
                    }}>
                        {cities.map((city, index) => (
                            <option value={city} key={index}>{city}</option>
                        ))}
                    </select>
                    <select placeholder='选择区域' name='area' onChange={(e) => {
                        handleChange('area', e)
                    }}>
                        {areas.map((area, index) => (
                            <option value={area} key={index}>{area}</option>
                        ))}
                    </select>
                    <Button type='primary-green' label="立即查询" handleClick={() => {
                        console.log(province, city, area);
                        //找到storeList中省市区相等的门店
                        const storeListFilter = storeList.filter((store) => {
                            return store.province === province && store.city === city && store.area === area
                        })
                        setStores(storeListFilter);

                    }}/>
                </div>


                {
                    stores.map((store, index) => {
                        return <div className="map-container" onClick={()=>{
                            //跳转到门店详情页
                            navigator(`/store-detail?position=${store.position}`);

                        }}>
                            <img src={store.storeImg} alt=""/>
                            <div className='storeContainer'>
                                <div className='storeName'>
                                    傲诺瓦助听器{store.type} {store.position}
                                </div>
                                <div className="info">
                                    <div className='item'>
                                        <Icon type='position'/>
                                        {store.address}
                                    </div>
                                    <div className='item'>
                                        <Icon type='time'/>
                                        {store.time}
                                    </div>
                                    <div className='item'>
                                        <Icon type='men'/>
                                        {store.phone}
                                    </div>
                                </div>
                            </div>
                        </div>
                    })
                }

                <div className="card-container">
                    <Card width={650} height={200} title='全部门店列表' other='立即探索' bgImg='/assets/store/query/c1.png'
                          color="#FFF" titleSize={36} url='/store'/>
                    <Card width={650} height={200} title='上门验配服务' other='立即了解' bgImg='/assets/store/query/c2.png'
                          color="#FFF" titleSize={36} url='/appointment'/>
                </div>
            </div>
        </>
    )
}
export default StoreQueryPage;