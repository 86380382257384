import React from 'react';
import CardHeader from "../../components/CardHeader/index.js";
import './index.css';
import {useNavigate} from "react-router-dom";
const StandardPage = () => {
    const navigator = useNavigate();
    return (
        <div className="StandardPage">
            <CardHeader title="服务标准"/>
            <img src="/assets/service/standard/1.png" className="desktop" alt=""/>
            <img src="/assets/service/standard/1_mobile.png" className="mobile" alt=""/>
            <div className="bottom-container">
                <img src="/assets/service/listen/card1.png" alt="" onClick={()=>{
                    navigator('/store-query ')
                }}/>
            </div>


        </div>
    )
}
export default StandardPage;