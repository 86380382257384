import React from "react";
import Icon from "../../../components/Icon";
import './index.css';
import {useNavigate} from "react-router-dom";

function ProductCard(props) {
    const {
        type,
        title,
        subTitle,
        rightImg,
        children,
    } = props;
    const [status, setStatus] = React.useState("normal");
    const navigate = useNavigate();

    if (children) {
        return (
            <div className={`productCard ${status}`}>
                <div className='top'>
                    <div className="left">
                        <div className='card-header'>
                            <div className='card-title'>{title}</div>
                            <div className='card-subtitle'>{subTitle}</div>
                        </div>
                        <div className='card-other' onClick={() => {
                            //切换到激活状态
                            if (status === 'active') {
                                setStatus("normal");
                            } else {
                                setStatus("active");
                            }
                        }}>立即查看<Icon type={status === 'active' ? 'minus-white' : 'plus'}/></div>
                    </div>
                    <div className="right">
                        <img src={rightImg} alt=""/>
                    </div>
                </div>
                {
                    status === 'active' && <div className='bottom'>
                        {children.map((item, index) => {
                            return <div className='child-card'>
                                <div className="left">
                                    <div className='card-header'>
                                        <div className='card-title'>{item.title}</div>
                                        <div className='card-subtitle'>{item.subTitle}</div>
                                    </div>
                                    <div className='card-other' onClick={() => {
                                        //react-router跳转到product/more页面
                                        //url编码参数
                                        const title = encodeURIComponent(item.title)
                                        const type = encodeURIComponent(item.type)
                                        navigate('/product/detail?title=' + title + '&type=' + type);
                                    }}>立即探索<Icon type='arrow-right-black'/></div>
                                </div>
                                <div className="right">
                                    <img src={item.rightImg} alt=""/>
                                </div>
                            </div>
                        })}
                    </div>
                }
            </div>
        )
    } else {
        return (
            <div className={`productCard ${status}`}>
                <div className='top'>
                    <div className="left">
                        <div className='card-header'>
                            <div className='card-title'>{title}</div>
                            <div className='card-subtitle'>{subTitle}</div>
                        </div>
                        <div className='card-other' onClick={() => {
                            navigate('/product/more?type=' + 3)

                        }}>立即探索<Icon type={'arrow-right-black'}/></div>
                    </div>
                    <div className="right">
                        <img src={rightImg} alt=""/>
                    </div>
                </div>

            </div>
        )
    }


}

export default ProductCard;