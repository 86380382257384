import React from "react";
import Button from "../Button";
import {URL} from "../../config/url.js";
import './index.css';
import {statistics} from "../../config/util";

function OptionBox() {
    return (
        <div className="optionBox desktop">
            <Button label={<><span>咨询热线</span><span>400 690 2288</span></>} type="primary-ghost" url='call'  handleClick={()=>{
                statistics({
                    actionType:"call"
                })
            }}/>
            <Button label="在线客服" type="primary-ghost" url={URL.customerService} handleClick={()=>{
                statistics({
                    actionType:"customerService"
                })
            }}/>
            <Button label="预约体验" type="primary-ghost" url='/appointment' handleClick={()=>{
                statistics({
                    actionType:"appointment"
                })
            }}/>
            <Button label="查询门店" type="primary-ghost" url='/store-query' handleClick={()=>{
                statistics({
                    actionType:"store-query"
                })
            }}/>
        </div>
    )

}

export default OptionBox;