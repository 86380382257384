import React, {useEffect} from 'react';
import './index.css';
import Button from "../../components/Button";
import {useSearchParams} from "react-router-dom";
import {getArticleById} from "../../config/util.js";

const ArticlePage = () => {
    const [article, setArticle] = React.useState({});
    //获取文章id
    const [getParams] = useSearchParams();
    const id = getParams.get('id');
    useEffect(() => {
        getArticleById(id).then(res => {
            const article = res.data.data.articleInfo;
            //如果tags是字符串
            if (typeof article.tags === 'string') {
                //按逗号分隔
                article.tags = article.tags.split(',');
            }
            setArticle(article);
            console.log(article);
        })
    }, [])
    return (
        <>
            <div className="ArticlePage mobile">
                <div className='title'>{article.title}</div>
                <div className='tagContainer'>
                    {article.tags && article.tags.map(tag => {
                        return <Button label={tag} type="primary-ghost"/>
                    })}
                </div>

                <div className="content">
                    {/*富文本展示html*/}
                    <div dangerouslySetInnerHTML={{__html: article.content}}/>
                </div>
            </div>
            <div className="ArticlePage desktop">
                <div className='title'>{article.title}</div>
                <div className='tagContainer'>
                    {article.tags && article.tags.map(tag => {
                        return <Button label={tag} type="primary-ghost"/>
                    })}
                </div>

                <div className="content">
                    {/*富文本展示html*/}
                    <div dangerouslySetInnerHTML={{__html: article.content}}/>
                </div>
            </div>
        </>

    )
}
export default ArticlePage;