import React, {useEffect} from 'react';
import SearchCard from "./SearchCard";
import './index.css';
import {useSearchParams} from "react-router-dom";
import {nav} from "../../config/nav";


//筛选出nav中的label含有query的项
const filterNav = (nav, query, result) => {
    //如果nav中的label含有query，则添加到searchResult中
    for (let i = 0; i < nav.length; i++) {

        if (nav[i].label.includes(query)) {
            //jump不等于none，则添加到searchResult中
            if (nav[i].jump !== 'no') {
                result.push(nav[i])
            }

        }
        if (nav[i].subItems) {
            filterNav(nav[i].subItems, query, result)
        }
    }
}
const SearchPage = () => {
    const [getParams] = useSearchParams()
    const query = getParams.get('query')
    const [searchResult, setSearchResult] = React.useState([])
    useEffect(() => {
        const search = []
        filterNav(nav, query, search)
        setSearchResult(search)
        console.log(search);
    }, [])
    return (
        <div className="SearchPage">
            <div className='card-container'>
                {searchResult.map(item => {
                    return <SearchCard label={item.label} key={item.key} url={item.link||item.key} description={item.description}/>
                })}
            </div>
        </div>
    )
}
export default SearchPage;