import React from "react";
import Icon from "../Icon";
import './index.css';
import {useNavigate} from "react-router-dom";

function CardHeader(props) {
    const {
        title,
        subtitle,
        other,
        url,
        id
    } = props;
    //判断type是不是以icon开头
    const navigate = useNavigate();
    return <div className='titleContainer' id={id}>
        <div className='left'>
            <span className="title">{title}</span>
            <span className="subtitle">{subtitle}</span>
        </div>
        <div className="right" onClick={() => {
            navigate(url)
        }}>
            <div className="other">{other}{other && <Icon type='arrow-right-green'/>}</div>
        </div>

    </div>

}

export default CardHeader;