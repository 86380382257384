import React from "react";
import Icon from "../Icon";
import './index.css';
import {useNavigate} from "react-router-dom";

function Card(props) {
    const {
        width,
        height,
        title,
        subTitle,
        other,
        bgImg,
        color,
        url,
        titleSize: fontSize,
    } = props;
    const navigator=useNavigate()
    return (
        <div className='card' style={{width, height, backgroundImage: `url(${bgImg})`, color}}>
            <div className='card-header'>
                <div className='card-title' style={{color, fontSize}}>{title}</div>
                <div className='card-subtitle' style={{color}}>{subTitle}</div>
            </div>
            <div className='card-other' style={{color}} onClick={()=>{
                if (url && url.includes('http')) {
                    window.location = url;
                } else {
                    url && navigator(url)
                }
            }}>{other}
                {color ? <Icon type={`arrow-right-white`}/> : <Icon type='arrow-right-black'/>}</div>
        </div>
    )

}

export default Card;