import React from 'react';
import CardHeader from "../../components/CardHeader";
import StoreCard from "./StoreCard";
import './index.css';

export const storeList = [
    {
        type: '旗舰体验中心',
        position: '局门路店',
        storeImg: 'assets/store/1.png',
        //地址
        address: '上海市黄浦区局门路468号1幢102-104',
        //运营时间
        time: '周一至周日 8:30-18:30',
        //座机电话
        phone: '021-63511298',
        //地铁
        subway: '4号线西藏南路站4号口 900米',
        //公交
        bus: '109路/146路 等',
        //省份
        province: '上海市',
        //城市
        city: '上海市',
        //区域
        area: '黄浦区',
    },
    {
        type: '体验中心',
        position: '紫荆广场店',
        storeImg: 'assets/store/2.png',
        //地址
        address: '上海市控江路1628号紫荆广场B1-05B',
        //运营时间
        time: '周一至周日 10:00-22:00',
        //座机电话
        phone: '021-55126018',
        //地铁
        subway: '8/18号线江浦路站3号口 170m',
        //公交
        bus: '115路 等',
        //省份
        province: '上海市',
        //城市
        city: '上海市',
        //区域
        area: '杨浦区',
    },
    {
        type: '体验中心',
        position: '天兴店',
        storeImg: 'assets/store/3.png',
        //地址
        address: '上海市虹口区四川北路2070号',
        //运营时间
        time: '周一至周日 08:30-17:30',
        //座机电话
        phone: '021-56777585',
        //地铁
        subway: '3号线东宝兴路站1号口 860m',
        //公交
        bus: '962路 等',
        //省份
        province: '上海市',
        //城市
        city: '上海市',
        //区域
        area: '虹口区',
    },
    {
        type: '体验中心',
        position: '松江店',
        storeImg: 'assets/store/4.png',
        //地址
        address: '上海市松江区中山中路549-551一层',
        //运营时间
        time: '周一至周日 08:30-17:30',
        //座机电话
        phone: '021-57729909',
        //地铁
        subway: '9号线醉白池站3号口 280m',
        //公交
        bus: '松江9路 等',
        //省份
        province: '上海市',
        //城市
        city: '上海市',
        //区域
        area: '松江区',
    },
    {
        type: '体验中心',
        position: '越商店',
        storeImg: 'assets/store/5.png',
        //地址
        address: '上海市静安区武宁南路1号越商大厦12楼-118',
        //运营时间
        time: '周一至周日 9:30-18:00',
        //座机电话
        phone: '021-52990779',
        //地铁
        subway: '14号线武定路站2号口 200m',
        //公交
        bus: '40路/824路 等',
        //省份
        province: '上海市',
        //城市
        city: '上海市',
        //区域
        area: '静安区',
    },
    {
        type: '体验中心',
        position: '十梓街店',
        storeImg: 'assets/store/6.png',
        //地址
        address: '苏州市姑苏区十梓街305号',
        //运营时间
        time: '周一至周日 8:00-17:30',
        //座机电话
        phone: '0512-65327668',
        //地铁
        subway: '1号线临顿路1号口 850米',
        //公交
        bus: '501路/511路 等',
        //省份
        province: '江苏省',
        //城市
        city: '苏州市',
        //区域
        area: '姑苏区',
    },
    {
        type: '体验中心',
        position: '紫峰店',
        storeImg: 'assets/store/14.png',
        mapImg: 'assets/store/detail/14_map.png',
        mapMobileImg: 'assets/store/detail/14_map_mobile.png',
        //地址
        address: '南京市鼓楼区中山北路6号紫峰广场1层B01-1',
        //运营时间
        time: '周一至周日 8:30-18:30',
        //座机电话
        phone: '025-86660915',
        //地铁
        subway: '1号线鼓楼站4A口',
        //公交
        bus: '1、3、33、35 等',
        //省份
        province: '江苏省',
        //城市
        city: '南京市',
        //区域
        area: '鼓楼区',
    },



    {
        type: '体验店',
        position: '梅岭店',
        storeImg: 'assets/store/11.png',
        //地址
        address: '上海市普陀区梅岭北路480号(康林仁和店内）',
        //运营时间
        time: '周一至周日 8:30-17:00',
        //座机电话
        phone: '15201725898',
        //地铁
        subway: '11号线枫桥路站3号口 790米',
        //公交
        bus: '165路 等',
        //省份
        province: '上海市',
        //城市
        city: '上海市',
        //区域
        area: '普陀区',
    },
    {
        type: '体验店',
        position: '宛平店',
        storeImg: 'assets/store/15.png',
        //地址
        address: '上海市徐汇区宛平南路660号',
        //运营时间
        time: '周一至周日 8:30-17:30',
        //座机电话
        phone: '021-54365589',
        //地铁
        subway: '7号线东安路站3号口',
        //公交
        bus: '167、301、44、50路等',
        //省份
        province: '上海市',
        //城市
        city: '上海市',
        //区域
        area: '徐汇区',
    },
    {
        type: '体验店',
        position: '斜土店',
        storeImg: 'assets/store/16.png',
        //地址
        address: '上海市徐汇区斜土路1411-1413号',
        //运营时间
        time: '周一至周日 8:30-18:30',
        //座机电话
        phone: '021-64313668',
        //地铁
        subway: '12/9号线嘉善路4号口',
        //公交
        bus: '104、327、45、572路等',
        //省份
        province: '上海市',
        //城市
        city: '上海市',
        //区域
        area: '徐汇区',
    },
    {
        type: '体验店',
        position: '平海店',
        storeImg: 'assets/store/17.png',
        mapImg: 'assets/store/detail/17_map.png',
        mapMobileImg: 'assets/store/detail/17_map_mobile.png',
        //地址
        address: '杭州市上城区平海路24号',
        //运营时间
        time: '周一至周日 8:00-18:30',
        //座机电话
        phone: '0571-85067028',
        //地铁
        subway: '1号线龙翔路站D口',
        //公交
        bus: '10、111、305、35、113路等',
        //省份
        province: '浙江省',
        //城市
        city: '杭州市',
        //区域
        area: '上城区',
    },



]
const StorePage = () => {
    return (
        <div className="StorePage">
            <CardHeader title="我们的门店"/>
            <div className='card-container'>
                {storeList.map(item => {
                    return <StoreCard type={item.type} position={item.position} storeImg={item.storeImg}/>
                })}
            </div>
        </div>
    )
}
export default StorePage;