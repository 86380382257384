import React from 'react';
import './index.css';


const JobPage = () => {

    return (
        <>
            <div className="JobPage desktop">
                {/*视频页面*/}
                <video controls>
                    <source src="https://sonova-home-v2.oss-cn-hangzhou.aliyuncs.com/evp%20video.mp4" type="video/mp4"/>
                </video>
                <div className="description">
                    作为Sonova集团的一员，我们所做的一切都是为了改变世界各地数百万人的听力体验。我们每天工作并深知，我们不懈的创新、合作精神和可持续的方法，帮助数以万计的人们感受聆听的快乐：更好的聆听，第一次感受音乐，与朋友和爱人轻松交流，充分享受拥有各种声音的美妙生活，这创造了意义。
                </div>
                <img src="/assets/job/c1.png" alt=""/>
                <div className="container">
                    <div className="left">
                        <div className="title">
                            职位投递
                        </div>
                        <div className="text-container">

                            <div className="text-container">
                                <p>请发送简历至 <a href="mailto:SonovaAC.HR@sonova.com">SonovaAC.HR@sonova.com</a> <br/>
                                    或扫码关注「AudioNova傲诺瓦」官方微信公众号，获取最新招聘信息。
                                </p>
                            </div>
                        </div>
                    </div>
                    <div className="right">
                        <img src="/assets/qrcode/wechat.png" alt=""/>
                    </div>
                </div>
            </div>
            <div className="JobPage mobile">
                <video controls>
                    <source src="https://sonova-home-v2.oss-cn-hangzhou.aliyuncs.com/evp%20video.mp4" type="video/mp4"/>
                </video>
                <img src="/assets/job/c1_mobile.png" alt=""/>

            </div>
        </>

    )
}
export default JobPage;