import './App.css';
import Nav from './components/Nav';
import {useEffect} from "react";
//导入路由组件
import {Route, Routes} from 'react-router';
import Index from "./pages/Index";
import ProductPage from "./pages/Product";
import ServicePage from "./pages/Service";
import StorePage from "./pages/Store";
import HearingPage from "./pages/Hearing";
import ListenerPage from "./pages/Listener";
import ArticlePage from "./pages/Article";
import AboutPage from "./pages/About";
import CompanyPage from "./pages/Company";
import HistoryPage from "./pages/History";
import ProductMorePage from "./pages/ProductMore";
import ProductDetailPage from "./pages/ProductDetail";
import ListenPage from "./pages/Listen";
import StandardPage from "./pages/Standard";
import StoreDetail from "./pages/StoreDetail";
import StoreQueryPage from "./pages/StoreQuery";
import FacilityPage from "./pages/Facility";
import TeamPage from "./pages/Team";
import FeaturePage from "./pages/Feature";
import CustomPage from "./pages/Custom";
import ActivityPage from "./pages/Activity";
import ContactPage from "./pages/Contact";
import JobPage from "./pages/Job";
import OptionBox from "./components/OptionBox";
import AppointmentPage from "./pages/Appointment";
import OptionIcon from "./components/OptionIcon";
import Footer from "./components/Footer";
import AdultPage from "./pages/Solution/Adult";
import KidPage from "./pages/Solution/Kid";
import NshiPage from "./pages/Solution/Nshi";
import TinnitusPage from "./pages/Solution/Tinnitus";
import SearchPage from "./pages/Search";
import ScrollToTop from "./components/ScrollToTop";

import {nav} from "./config/nav.js";

function App() {
    useEffect(() => {
        // FullPage.scrollToSlide(2);
    }, [])
    //判断是不是手机版页面
    const isMobile = () => {
        if (window.innerWidth <= 480) {
            return true;
        } else {
            return false;
        }
    }
    const dir = '/'
    return (
        <>
            <Nav items={nav} search/>
            <div className="container">
                <ScrollToTop>
                <Routes>
                    <Route path={dir}  element={<Index isMobile={isMobile()}/>}/>
                    <Route path={`${dir}product`}  element={<ProductPage/>}/>
                    <Route path={`${dir}product/more`}  element={<ProductMorePage/>}/>
                    <Route path={`${dir}product/detail`}  element={<ProductDetailPage/>}/>
                    <Route path={`${dir}service`}  element={<ServicePage/>}/>
                    <Route path={`${dir}service/standard`}  element={<StandardPage/>}/>
                    <Route path={`${dir}service/facility`}  element={<FacilityPage/>}/>
                    <Route path={`${dir}service/team`}  element={<TeamPage/>}/>
                    <Route path={`${dir}service/feature`}  element={<FeaturePage/>}/>
                    <Route path={`${dir}service/custom`}  element={<CustomPage/>}/>
                    <Route path={`${dir}store`}  element={<StorePage/>}/>
                    <Route path={`${dir}store-detail`}  element={<StoreDetail/>}/>
                    <Route path={`${dir}store-query`}  element={<StoreQueryPage/>}/>
                    <Route path={`${dir}knowledge`}  element={<HearingPage/>}/>
                    <Route path={`${dir}listener`}  element={<ListenerPage/>}/>
                    <Route path={`${dir}article`}  element={<ArticlePage/>}/>
                    <Route path={`${dir}about`}  element={<AboutPage/>}/>
                    <Route path={`${dir}company`}  element={<CompanyPage/>}/>
                    <Route path={`${dir}history`}  element={<HistoryPage/>}/>
                    <Route path={`${dir}listen`}  element={<ListenPage/>}/>
                    <Route path={`${dir}activity`}  element={<ActivityPage/>}/>
                    <Route path={`${dir}contact`}  element={<ContactPage/>}/>
                    <Route path={`${dir}job`}  element={<JobPage/>}/>
                    <Route path={`${dir}appointment`}  element={<AppointmentPage/>}/>
                    <Route path={`${dir}/solution/Adult`}  element={<AdultPage/>}/>
                    <Route path={`${dir}/solution/Kid`}  element={<KidPage/>}/>
                    <Route path={`${dir}/solution/Nshi`}  element={<NshiPage/>}/>
                    <Route path={`${dir}/solution/Tinnitus`}  element={<TinnitusPage/>}/>
                    <Route path={`${dir}/search`}  element={<SearchPage/>}/>
                    <Route path={'*'}  element={<Index isMobile={isMobile()}/>}/>
                    {/*重定向到首页*/}
                </Routes>
                </ScrollToTop>
            </div>
            <Footer/>
            <OptionBox/>
            <OptionIcon/>
        </>
    );
}

export default App;
