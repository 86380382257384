import React from "react";
import Button from "../Button";
import {URL} from "../../config/url.js";
import './index.css';
import {statistics} from "../../config/util";

function OptionIcon() {
    const [show, setShow] = React.useState(false);
    return (
        <div className='OptionContainer mobile'>
            <div className="OptionIcon" style={{visibility: show ? "visible" : "hidden"}}>
                <Button label="咨询热线" type="primary-ghost" url='call' handleClick={()=>{
                    statistics({
                        actionType:"call"
                    })
                }}/>
                <Button label="在线客服" type="primary-ghost" url={URL.customerService} handleClick={()=>{
                    statistics({
                        actionType:"customerService"
                    })
                }}/>
                <Button label="查询门店" type="primary-ghost" url='/store-query' handleClick={()=>{
                    statistics({
                        actionType:"store-query"
                    })
                }}/>
            </div>
            <div className="OptionBottom">
                <Button type="primary-green" label="立即预约体验" url='/appointment' handleClick={()=>{
                    statistics({
                        actionType:"appointment"
                    })
                }}/>
                <img src={`/assets/option-icon-${show ? 'active' : 'normal'}.png`} alt="" onClick={() => {
                    setShow(!show);
                }}/>
            </div>

        </div>

    )

}

export default OptionIcon;