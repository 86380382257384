export const URL = {
    //客服
    customerService: 'https://sonova.s2.udesk.cn/im_client/?web_plugin_id=29653',
    jd: 'https://mall.jd.hk/index-11575797.html',
    tmall: 'https://audionova.tmall.com/shop/view_shop.htm?spm=a230r.7195193.1997079397.2.29875546GYzRYG',
    zhihu: 'https://www.zhihu.com/org/audionovaao-nuo-wa-ting-li-shi-jie',
    weibo: 'https://weibo.com/p/1006067486659252/home?from=page_100606&mod=TAB#place',
    //在线听力测试
    test: 'http://dtt.audionova.com.cn/#/iPad',
    //文章列表API测试https://sonova.youzijie.com/sonova/h5/api/v2/official/article/list
    articleList: 'https://sonova.youzijie.com/sonova/h5/api/v2/official/article/list',
    //文章ID API地址
    articleId: 'https://sonova.youzijie.com/sonova/h5/api/v2/official/article/get?articleId=',
    //预约api地址
    order: 'https://sonova.youzijie.com/sonova/h5/api/v2/official/reservation/add',
    //埋点API地址
    statistics:"https://woh.worldofhearing.cn/sonova/h5/api/v2/track/add"


}