// 我们的产品组件
import React from 'react';
import CardHeader from "../../components/CardHeader/index.js";
import ProductCard from "./ProductCard";
import './index.css';
import { useSearchParams } from "react-router-dom";

const cardList1 = [
    {
        title: '安馨宝充电盒',
        subTitle: 'Charge & Care',
        rightImg: 'assets/product/more/1.png',
        other: '集消毒、干燥、充电、收纳作用于一身的充电盒，旨在让您的助听器使用更环保、更安心！',
    },
    {
        title: '多功能充电盒',
        subTitle: 'Charger case Combi',
        rightImg: 'assets/product/more/2.png',
        other: '充电干燥两不误。高效充电管理，3小时充满，30分钟快充30%电量。',
    },
    {
        title: '随行充电盒2.0',
        subTitle: 'Charger RIC BTE',
        rightImg: 'assets/product/more/3.png',
        other: '充电干燥两不误。3小时充满，30分钟快充30%电量，旅途更省心。',
    },
    {
        title: '蓄电能量块',
        subTitle: 'Power pack Marvel',
        rightImg: 'assets/product/more/4.png',
        other: '可为助听器充电7次，满足一周旅行所需。',
    },
    {
        title: '络易聆（直连型）',
        subTitle: 'Roger Select iN',
        rightImg: 'assets/product/more/5.png',
        other: '更高的可听度与高频声音，更佳的言语理解度，让您在生活的各个场景中，沟通再无障碍。',
    },
    {
        title: '视听通（杜比声）',
        subTitle: 'TV Connector Dolby',
        rightImg: 'assets/product/more/6.png',
        other: '即插即用，杜比立体声输出。连接您的电视、音响、电脑等设备，让您和家人一起无忧共享精彩节目。',
    },
    {
        title: '视听通',
        subTitle: 'TV Connector 1.1',
        rightImg: 'assets/product/more/7.png',
        other: '即插即用，立体声输出。连接您的电视、音响、电脑等设备，让您和家人一起无忧共享精彩节目。',
    },
    {
        title: '麦灵通（直连型）',
        subTitle: 'PartnerMic',
        rightImg: 'assets/product/more/8.png',
        other: '聚焦言语并能远距离传输至您的助听器，非常适合在嘈杂环境或远距离的一对一对话。小巧外观，便于佩戴。',
    },
]
const cardList2 = [
    {
        title: '多功能充电盒',
        subTitle: 'Easy line Charger R Li',
        rightImg: 'assets/product/more/2-1.png',
        other: '充电干燥两不误。高效充电管理，3小时充满，30分钟快充30%电量。',
    },
    {
        title: '言语增强麦克风',
        subTitle: 'PartnerMic Easy Line',
        rightImg: 'assets/product/more/2-2.png',
        other: '聚焦言语并能远距离传输至您的助听器，非常适合在嘈杂环境或远距离的一对一对话。小巧外观，便于佩戴。',
    },
    {
        title: '直连遥控器',
        subTitle: 'Remote Control Easy Line',
        rightImg: 'assets/product/more/2-3.png',
        other: '大按键、目视操作，让助听器的音量调整和程序切换更随心所欲。',
    },
    {
        title: '电视直连伴侣',
        subTitle: 'TV Connector Easy Line',
        rightImg: 'assets/product/more/2-4.png',
        other: '即插即用。连接电视、音响、电脑等设备，让您和家人一起无忧共享精彩节目。',
    }
]
const cardList3 = [
    {
        title: '睡眠降噪耳塞',
        subTitle: 'AudioNova™ SleepTight',
        rightImg: 'assets/product/more/3-1.png',
        other: '无忧防护，好梦相伴。',
    },
    {
        title: '游泳防护耳塞',
        subTitle: 'AudioNova™ Swim',
        rightImg: 'assets/product/more/3-2.png',
        other: '无忧防护，畅快运动。',
    },
    {
        title: '音乐降噪耳塞',
        subTitle: 'AudioNova™ Music',
        rightImg: 'assets/product/more/3-3.png',
        other: '无忧防护，纯净天籁。',
    },
    {
        title: '车噪防护耳塞',
        subTitle: 'AudioNova™ Drive',
        rightImg: 'assets/product/more/3-4.png',
        other: '无忧防护，自在一路。',
    },
    {
        title: '作业降噪耳塞',
        subTitle: 'AudioNova™ Active',
        rightImg: 'assets/product/more/3-4.png',
        other: '无忧防护，安心保障。',
    },
    {
        title: '商旅降噪耳塞',
        subTitle: 'AudioNova™ InFlight',
        rightImg: 'assets/product/more/3-4.png',
        other: '无忧防护，畅行旅途。',
    }
]
const ProductMorePage = () => {
    // 函数组件获取url参数
    const [getParams] = useSearchParams()
    const type = getParams.get('type')
    switch (parseInt(type)) {
        case 1: {
            return <div className="ProductMorePage">
                <CardHeader title="傲诺瓦 助听器辅件"/>
                {cardList1.map(item => {
                    return <ProductCard title={item.title} subTitle={item.subTitle} rightImg={item.rightImg}
                                        other={item.other}/>
                })}
            </div>
            break;
        }
        case 2: {
            return (
                <div className="ProductMorePage">
                    <CardHeader title="傲诺瓦 助听器辅件"/>
                    {cardList2.map(item => {
                        return <ProductCard title={item.title} subTitle={item.subTitle} rightImg={item.rightImg}
                                            other={item.other}/>
                    })}
                </div>

            )
            break;

        }
        case 3: {
            return (
                <div className="ProductMorePage type3">
                    <CardHeader title="傲诺瓦 降噪耳塞"/>
                    {cardList3.map(item => {
                        return <ProductCard title={item.title} subTitle={item.subTitle} rightImg={item.rightImg}
                                            other={item.other}/>
                    })}
                </div>

            )
            break;
        }

    }

}
export default ProductMorePage;