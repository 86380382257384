import {URL} from "./url.js";

export const nav = [
    {
        label: '关于傲诺瓦',
        key: 'about-sonova',
        jump: 'no',
        subItems: [
            {
                label: '品牌介绍',
                key: 'about',
                description:'AudioNova傲诺瓦致力于让所有听损人士回归品质生活，同时为您提供从听力早筛、助听器及听力康复等一站式的专业服务和解决方案。'
            },
            {
                label: 'SONOVA集团',
                key: 'company',
                description:'SONOVA集团是一家专业从事听力技术研发、生产、销售的公司。'
            },
            {
                label: '集团历史',
                key: 'history',
                description:'自「AGfürElektroakustik」于1947年创建起，索诺瓦集团一直持续地扩大市场份额，为其所有利益相关方创造增值价值。这归功于不断研发的创新产品和品牌组合，以及对经销网络的不断专业化。多年以来，索诺瓦在助听器领域依靠市场主导、多元化品牌和产品组合取得成功。在索诺瓦，我们提供峰力、优利康和汉莎通等品牌。这些品牌是行业内全面、高品质的助听器产品系列。AudioNova傲诺瓦品牌下的听力保健提供商可提供迅速发展的国际化专业性服务网络，在此支持下，集团持续巩固自己在该业务领域的市场领先地位。'
            }
        ]
    },
    {
        label: '我们的产品',
        key: 'product',
        description:'PHONAK峰力系列微型耳背式助听器RICHearingAids立即查看经典耳背式助听器BTEHearingAids立即查看耳内式助听器ITEHearingAids立即查看儿童助听器ITEHearingAids立即查看降噪耳塞Earplugs立即查看峰力助听器辅件PHONAKHIAccessories立即探索AudioNova傲诺瓦系列微型耳背式助听器RICHearingAids立即查看经典耳背式助听器BTEHearingAids立即查看耳内式助听器ITEHearingAids立即查看降噪耳塞Earplugs立即探索傲诺瓦助听器辅件AudioNovaHIAccessories'
    },
    {
        label: '我们的服务',
        key: 'service',
        description: "听力解决方案立即探索服务标准立即探索专业设施立即探索专家团队立即探索特色服务立即探索私人定制立即探索",
        subItems: [
            {
                label: '听力解决方案',
                key: 'listen',
                description: '听力解决方案成人听力解决方案立即探索儿童听力解决方案立即探索单侧耳聋解决方案立即探索耳鸣管理'
            },
            {
                label: '服务标准',
                key: 'service/standard',
                description: 'AudioNova傲诺瓦专注守护各年龄段听损人士的听力健康，倾力打造国际化服务标杆，为每一个消费者量身定制个性化验配方案，确保全链路可追溯，主客观数据共依存，实现医疗级的精准校验，以人为本的验配模式及以家庭为中心的康复模式，让消费者时刻感受到高水准的专业服务。'
            },
            {
                label: '专业设施',
                key: "service/facility",
                description: 'AudioNova傲诺瓦拥有行业领先的助听器验配设备，完善且尖端的验配工具帮助我们的验配师为不同听损程度的人士提供专业的一站式服务。'
            },
            {
                label: '专家团队',
                key: "service/team",
                description: '依托索诺瓦强大的技术力量和纵贯全球的专家团队，每天有超过1000名听力专家为听损人群带来更加专业和贴心的服务。'
            },
            {
                label: '特色服务',
                key: "service/feature",
                description: '风雨无阻，注重实效。我们致力于为每一位顾客提供一站式的听力健康解决方案，无论是您自己还是您的家人存在任何听力问题，都能在这里得到专业可信赖的服务。'
            },
            {
                label: '私人定制',
                key: "service/custom",
                description: '每一台定制助听器的背后，都是一个团队一群人的共同努力，确保符合您的听力图和耳道情况。'
            }
        ]
    },
    {
        label: '我们的门店',
        key: 'store',
        jump: 'no',
        subItems: [
            {
                label: '门店查询',
                key: 'store-query',
                description: '选择省份，选择城市，选择区域'
            },
            {
                label: '全部门店列表',
                key: 'store',
                description: '傲诺瓦助听器旗舰体验中心局门路店傲诺瓦助听器体验中心天兴店傲诺瓦助听器体验中心越商店傲诺瓦助听器体验店宜山店傲诺瓦助听器体验店沪闵店傲诺瓦助听器体验店梅岭店傲诺瓦助听器体验店仙霞店傲诺瓦助听器体验中心紫荆广场店傲诺瓦助听器体验中心松江店傲诺瓦助听器体验中心十梓街店傲诺瓦助听器体验店长海店傲诺瓦助听器体验店腾越店傲诺瓦助听器体验店莘松店'
            },
            {
                label: '天猫商城',
                key: "tmall",
                link: URL.tmall,
                description: '天猫商城'
            },
            {
                label: '京东商城',
                key: "jd",
                link: URL.jd,
                description: '京东商城'
            },
        ]
    },
    {
        label: '听力百科',
        key: 'hearing',
        jump: 'no',
        subItems: [
            {
                label: '在线听力测试',
                key: "test",
                link: URL.test,
                description: '在线听力测试'
            },
            {
                label: '听力科普',
                key: "knowledge",
                description: '听力科普文章列表'
            },
            {
                label: '助听器科普',
                key: "listener",
                description: '助听器科普文章列表'
            },
        ]
    },
    {
        label: '活动中心',
        key: 'activity',
        description: '活动中心文章列表',
    },
    {
        label: '联系我们',
        key: 'contact',
        description: '联系我们',
    },
    {
        label: '工作机会',
        key: 'job',
        description: '工作机会',
    }
]