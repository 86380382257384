// 我们的产品组件
import React from 'react';
import CardHeader from "../../components/CardHeader/index.js";
import ProductCard from "./ProductCard";
import GrayCard from "./GrayCard";
import './index.css';
export const productList1 = [
    {
        title: '微型耳背式助听器',
        subTitle: 'RIC Hearing Aids',
        rightImg: 'assets/product/card/bg1.png',
        children: [
            {
                type:'微型耳背式助听器',
                title: '峰力 奥笛·天朗',
                subTitle: 'PHONAK Audéo P',
                price:'10,500',
                rightImg: 'assets/product/detail/1_product.png',
                contentD: 'assets/product/detail/1_content_desktop.png',
                contentM: 'assets/product/detail/1_content_m.png',
            },
            {
                type:'微型耳背式助听器',
                title: '峰力 奥笛·神采',
                subTitle: 'PHONAK Audéo M',
                price:'9,900',
                rightImg: 'assets/product/detail/2_product.png',
                contentD: 'assets/product/detail/2_content_desktop.png',
                contentM: 'assets/product/detail/2_content_m.png',
            }
        ],
    },
    {
        title: '经典耳背式助听器',
        subTitle: 'BTE Hearing Aids',
        rightImg: 'assets/product/card/bg2.png',
        children: [
            {
                type:'经典耳背式助听器',
                title: '峰力 美人鱼·天朗',
                subTitle: 'PHONAK Naída P',
                price:'10,500',
                rightImg: 'assets/product/detail/3_product.png',
                contentD: 'assets/product/detail/3_content_desktop.png',
                contentM: 'assets/product/detail/3_content_m.png',
            },
            {
                type:'经典耳背式助听器',
                title: '峰力 芭蕾·神采',
                subTitle: 'PHONAK Naída M',
                price:'9,900',
                rightImg: 'assets/product/detail/4_product.png',
                contentD: 'assets/product/detail/4_content_desktop.png',
                contentM: 'assets/product/detail/4_content_m.png',
            }
        ],
    },
    {
        title: '耳内式助听器',
        subTitle: 'ITE Hearing Aids',
        rightImg: 'assets/product/card/bg3.png',
        children: [
            {
                type:'耳内式助听器',
                title: '峰力 云图·神采',
                subTitle: 'PHONAK Virto M',
                price:'8,900',
                rightImg: 'assets/product/detail/5_product.png',
                contentD: 'assets/product/detail/5_content_desktop.png',
                contentM: 'assets/product/detail/5_content_m.png',
            },
            {
                type:'耳内式助听器',
                title: '峰力 黑曜石·神采',
                subTitle: 'PHONAK Virto M-Black',
                price:'10,900',
                rightImg: 'assets/product/detail/6_product.png',
                contentD: 'assets/product/detail/6_content_desktop.png',
                contentM: 'assets/product/detail/6_content_m.png',
            },
            {
                type:'耳内式助听器',
                title: '峰力 钛斗·神采',
                subTitle: 'PHONAK Virto M-Titanium',
                price:'25,900',
                rightImg: 'assets/product/detail/7_product.png',
                contentD: 'assets/product/detail/7_content_desktop.png',
                contentM: 'assets/product/detail/7_content_m.png',
            }
        ],
    },
    {
        title: '儿童助听器',
        subTitle: 'Hearing Aids for Children',
        rightImg: 'assets/product/card/bg4.png',
        children: [
            {
                type:'儿童助听器',
                title: '峰力 美人鱼·天朗',
                subTitle: 'PHONAK Naída P',
                price:'10,500',
                rightImg: 'assets/product/detail/3_product.png',
                contentD: 'assets/product/detail/3_content_desktop.png',
                contentM: 'assets/product/detail/3_content_m.png',
            },
            {
                type:'儿童助听器',
                title: '峰力 美人鱼·神采',
                subTitle: 'PHONAK Sky M',
                price:'10,900',
                rightImg: 'assets/product/detail/8_product.png',
                contentD: 'assets/product/detail/8_content_desktop.png',
                contentM: 'assets/product/detail/8_content_m.png',
            }
        ],
    },
]

export const productList2 = [
    {
        title: '微型耳背式助听器',
        subTitle: 'RIC Hearing Aids',
        rightImg: 'assets/product/card/bg1_c2.png',
        children: [
            {
                type:'微型耳背式助听器',
                title: '傲诺瓦 声跃·清',
                subTitle: 'AudioNova B',
                price:'3,900',
                rightImg: 'assets/product/detail/9_product.png',
                contentD: 'assets/product/detail/9_content_desktop.png',
                contentM: 'assets/product/detail/9_content_m.png',

            },
            {
                type:'微型耳背式助听器',
                title: '傲诺瓦 声跃',
                subTitle: 'AudioNova DX',
                price:'5,900',
                rightImg: 'assets/product/detail/10_product.png',
                contentD: 'assets/product/detail/10_content_desktop.png',
                contentM: 'assets/product/detail/10_content_m.png',
            },
            {
                type:'微型耳背式助听器',
                title: '傲诺瓦 悦笙',
                subTitle: 'AudioNova Basic+',
                price:'3,900',
                rightImg: 'assets/product/detail/11_product.png',
                contentD: 'assets/product/detail/11_content_desktop.png',
                contentM: 'assets/product/detail/11_content_m.png',
            }
        ],
    },
    {
        title: '经典耳背式助听器',
        subTitle: 'BTE Hearing Aids',
        rightImg: 'assets/product/card/bg2_c2.png',
        children: [
            {
                type:'经典耳背式助听器',
                title: '傲诺瓦 声跃·清',
                subTitle: 'AudioNova B',
                price:'4,900',
                rightImg: 'assets/product/detail/12_product.png',
                contentD: 'assets/product/detail/12_content_desktop.png',
                contentM: 'assets/product/detail/12_content_m.png',
            },
            {
                type:'经典耳背式助听器',
                title: '傲诺瓦 声跃',
                subTitle: 'AudioNova DX',
                price:'5,900',
                rightImg: 'assets/product/detail/13_product.png',
                contentD: 'assets/product/detail/13_content_desktop.png',
                contentM: 'assets/product/detail/13_content_m.png',
            },
            {
                type:'经典耳背式助听器',
                title: '傲诺瓦 悦笙',
                subTitle: 'AudioNova Basic+',
                price:'3,290',
                rightImg: 'assets/product/detail/14_product.png',
                contentD: 'assets/product/detail/14_content_desktop.png',
                contentM: 'assets/product/detail/14_content_m.png',
            }
        ],
    },
    {
        title: '耳内式助听器',
        subTitle: 'ITE Hearing Aids',
        rightImg: 'assets/product/card/bg3_c2.png',
        children: [
            {
                type:'耳内式助听器',
                title: '傲诺瓦 声跃',
                subTitle: 'AudioNova DX',
                price:'5,900',
                rightImg: 'assets/product/detail/15_product.png',
                contentD: 'assets/product/detail/15_content_desktop.png',
                contentM: 'assets/product/detail/15_content_m.png',
            },
            {
                type:'耳内式助听器',
                title: '傲诺瓦 悦笙',
                subTitle: 'AudioNova Basic+',
                price:'3,790',
                rightImg: 'assets/product/detail/16_product.png',
                contentD: 'assets/product/detail/16_content_desktop.png',
                contentM: 'assets/product/detail/16_content_m.png',
            },

        ],
    },
    {
        title: '降噪耳塞',
        subTitle: 'Earplugs',
        rightImg: 'assets/product/card/bg4_c2.png',
        // children:[]
    },

]
const ProductPage = () => {
    return (
        <div className="ProductPage">
            <CardHeader title="PHONAK 峰力系列" id={1}/>
            {productList1.map(item => {
                return <ProductCard type={item.type} title={item.title} subTitle={item.subTitle} rightImg={item.rightImg} children={item.children}/>
            })}
            <GrayCard title="峰力 助听器辅件" subTitle="PHONAK HI Accessories" type={1}/>
            <CardHeader title="AudioNova 傲诺瓦系列" id={2}/>
            {productList2.map(item => {
                return <ProductCard type={item.type} title={item.title} subTitle={item.subTitle} rightImg={item.rightImg} children={item.children}/>
            })}
            <GrayCard title="傲诺瓦 助听器辅件" subTitle="AudioNova HI Accessories" type={2}/>
        </div>
    )
}
export default ProductPage;